import * as THREE from 'three';
import { readValue, storeValue } from "./storage";
import * as Sentry from "@sentry/react";
import { Capacitor } from '@capacitor/core';
import { messageDiscord } from './discord';

export type AnalyticsPayload = {
  version: number;
  values: {
    uuid: string;
    abID: string;
    visitedPages: Array<{
      path: string;
      time: number;
      timeSpent: number;
      interactions: string[];
    }>;
    converted: boolean;
    returned: number;
    signedIn: boolean;
    paid: boolean;
    usage: {
      uploadsStarted: number;
      uploadsFinished: number;
      uploadErrors: number;
      uploadTimes: number[];
      cancellations: number;
      exports: number;
    };
    platform: 'web' | 'ios' | 'android' | 'unknown';
  };
}

export let analyticsReturningSession = false;
export let analyticsUuid: string = (() => {
  const existingUuid = localStorage.getItem('analyticsUuid');

  if (!existingUuid) {
    const newUuid = `0x${THREE.MathUtils.generateUUID()}`;

    localStorage.setItem('analyticsUuid', newUuid);

    return newUuid;
  } else {
    analyticsReturningSession = true;

    return existingUuid;
  }
})();
let analyticsCache: AnalyticsPayload | null = null;
let debouncedSave: NodeJS.Timeout | null = null;

export const hasRatedApp: number | false = (() => {
  const existingRating = localStorage.getItem('analyticsHasRatedApp');

  if (!existingRating) {
    return false;
  } else {
    return parseInt(existingRating);
  }
})();
export const markAppRating = (rating: number) => {
  localStorage.setItem('analyticsHasRatedApp', `${rating}`);
};

const requestSave = async () => {
  if (debouncedSave) {
    clearTimeout(debouncedSave);
  }

  debouncedSave = setTimeout(async () => {
    await storeValue(analyticsCache, 'analytics');
  }, 2000);
};

export const setupAnalytics = async () => {
  if (analyticsCache) {
    return;
  }

  let analyticsPayload;

  try {
    analyticsPayload = await readValue<AnalyticsPayload>('analytics');
  } catch (error) {
    Sentry.captureException(error);
  }

  if (!analyticsPayload) {
    analyticsPayload = {
      version: 1,
      values: {
        uuid: THREE.MathUtils.generateUUID(),
        abID: 'A',
        visitedPages: [
          {
            path: "AppView",
            time: Date.now(),
            timeSpent: 0,
            interactions: []
          }
        ],
        converted: false,
        returned: 0,
        signedIn: false,
        paid: false,
        usage: {
          uploadsStarted: 0,
          uploadsFinished: 0,
          uploadErrors: 0,
          uploadTimes: [],
          cancellations: 0,
          exports: 0,
        },
        platform: 'unknown' as ('web' | 'ios' | 'android' | 'unknown'),
      }
    };
  } else {
    recordAnalyticsValue({
      returned: analyticsPayload.values.returned + 1,
    });
  }

  // NOTE Compatibility v1.2
  if (Capacitor.getPlatform() === 'android') {
    analyticsPayload.values.platform = 'android';
  } else if (Capacitor.getPlatform() === 'ios') {
    analyticsPayload.values.platform = 'ios';
  } else {
    analyticsPayload.values.platform = 'web';
  }

  await storeValue(analyticsPayload, 'analytics');

  analyticsCache = analyticsPayload;
};

export const recordExport = async () => {
  if (!analyticsCache) return;

  analyticsCache.values.usage.exports++;
};

export const recordUploadError = async () => {
  if (!analyticsCache) return;

  analyticsCache.values.usage.uploadErrors++;

  requestSave();
};

export const recordUploadCancellation = async () => {
  if (!analyticsCache) return;

  analyticsCache.values.usage.cancellations++;

  requestSave();
};

export const recordUploadFinished = async (time: number) => {
  if (!analyticsCache) return;

  analyticsCache.values.usage.uploadsFinished++;
  analyticsCache.values.usage.uploadTimes.push(time);

  requestSave();
};

export const recordUploadStart = async () => {
  if (!analyticsCache) return;

  analyticsCache.values.usage.uploadsStarted++;

  requestSave();
};

export const recordPage = async (pathOrIndex: string | number, timeSpent: number, interactions: string[]): Promise<number | undefined> => {
  if (!analyticsCache) return;

  if (typeof pathOrIndex === 'string') {
    analyticsCache.values.visitedPages.push({
      path: pathOrIndex,
      time: Date.now(),
      timeSpent,
      interactions
    });
  } else if (analyticsCache.values.visitedPages[pathOrIndex]) {
    analyticsCache.values.visitedPages[pathOrIndex].timeSpent = timeSpent;
    analyticsCache.values.visitedPages[pathOrIndex].interactions = interactions;
  }

  requestSave();
  
  return analyticsCache.values.visitedPages.length - 1;
};

export const recordAnalyticsValue = async (payload: Partial<Record<
  'abID' |
  'converted' |
  'returned' |
  'signedIn' |
  'paid',
  any
>>) => {
  if (!analyticsCache) return;

  Object.keys(payload).forEach((key) => {
    // @ts-ignore
    analyticsCache.values[key] = payload[key];
  });

  requestSave();
};

export const setupWebsocketLiveConnection = async () => {
  const socket = new WebSocket("wss://gpu-host-th-2.wtlstudio-offsite.com");
  let updateInterval: NodeJS.Timeout | null = null;

  socket.addEventListener("open", () => {
    updateInterval = setInterval(() => {
      socket.send(new Uint8Array([1]));
    }, 5000);
  });

  socket.addEventListener("error", () => {
    if (updateInterval) {
      clearInterval(updateInterval);
    }
  });

  socket.addEventListener("close", () => {
    if (updateInterval) {
      clearInterval(updateInterval);
    }
  });
};

export const checkEvents = () => {
  setTimeout(() => {
    messageDiscord('👀 Stayed in app more than 30seconds');
  }, 30 * 1000);

  setTimeout(() => {
    messageDiscord('🤔 Stayed in app more than 1min');
  }, 60 * 1000);

  setTimeout(() => {
    messageDiscord('😳 Stayed in app more than 5min');
  }, 5 * 60 * 1000);
};

export const getOperatingSystem = () => {
  try {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
  } catch {
    // NOTE No-op
  }

  return "web";
}
