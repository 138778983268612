import { HTMLAttributes, useRef } from "react";
import styled from "styled-components";

export const Icon = ({
  name,
  size = 16,
  padding = 6,
  rotation = 0,
  color,
  backgroundColor,
  highlight = false,
  inverted = false,
  shadow = false,
  ...rest
}: {
  name: string;
  size: number;
  padding?: number;
  rotation?: number;
  color?: string;
  backgroundColor?: string;
  highlight?: boolean;
  inverted?: boolean;
  shadow?: boolean;
} & HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Container
      ref={containerRef}
      $size={size}
      $color={color}
      $invert={inverted}
      $name={name}
      $padding={padding}
      $shadow={shadow}
      {...rest}
      onClick={(event) => {
        if (!rest.onClick) {
          return;
        }

        if (containerRef.current) {
          containerRef.current.animate([
            { transform: `scale(0.5)` },
            { transform: `scale(1.2)` },
            { transform: `scale(1.0)` },
          ], {
            duration: 200,
            easing: "ease-in-out",
          });
        }

        rest.onClick(event);
      }}
    >
      <IconMask
        $name={name}
        $size={size}
        $padding={padding}
        $rotation={rotation}
        $color={color}
        $invert={inverted}
      />
      {highlight && (
        <Highlight
          $padding={padding}
        />
      )}
      {backgroundColor && (
        <Background $backgroundColor={backgroundColor} />
      )}
    </Container>
  );
};

const Background = styled.div<{
  $backgroundColor: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  z-index: 0;
  border-radius: 50%;
`;

const Highlight = styled.div<{
  $padding: number;
}>`
  @keyframes HighlightPopIn {
    0% {
      transform: translate(-50%, -50%) scale(0.0);
      filter: blur(0px);
      -webkit-filter: blur(0px);
    }

    60% {
      transform: translate(-50%, -50%) scale(1.2);
      filter: blur(4px);
      -webkit-filter: blur(4px);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
      filter: blur(0px);
      -webkit-filter: blur(0px);
    }
  }

  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + ${({ $padding }) => $padding}px);
  height: calc(100% + ${({ $padding }) => $padding}px);
  transform: translate(-50%, -50%);
  background-color: #ffffff11;
  border-radius: 50%;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 1;
  animation: HighlightPopIn 0.1s ease-in-out alternate 1;
`;

const IconMask = styled.div<{
  $name: string;
  $size: number;
  $padding: number;
  $rotation: number;
  $color?: string;
  $invert?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(${({ $rotation }) => $rotation}rad);
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  mask: url(${({ $name }) => `./icons/${$name}.svg`});
  mask-size: calc(100% - ${({ $padding }) => $padding * 2}px);
  mask-position: center;
  mask-repeat: no-repeat;
  background: ${({ $color }) => $color || "#cccccc"};
  z-index: 2;

  ${({ $invert, $color }) => $invert ? `
    background: ${$color};
    filter: invert(1);
  ` : ''}
`;

const Container = styled.div<{
  $size: number;
  $color?: string;
  $invert?: boolean;
  $name?: string;
  $padding?: number;
  $shadow?: boolean;
}>`
  @keyframes ContainerClickAnimation {
    0% {
      transform: scale(0.5);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1.0);
    }
  }

  position: relative;
  display: inline-block;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;

  ${({ $invert, $color }) => $invert ? `
    background-color: ${$color};
    border-radius: 50%;
  ` : ''}

  ${({ $shadow }) => $shadow ? `
    filter: drop-shadow(0px 4px 8px #00000088);
  ` : ''}
`;
