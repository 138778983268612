
const translationEnglish = {
  openPicture: 'Open Picture',
  preparingPicture: 'Preparing Picture',
  cancelOption: 'Cancel?',
  cancellingPicture: 'Cancelling',

  toolRevert: 'Revert All',
  toolRotate: 'Rotate',
  toolCrop: 'Crop',
  toolFlip: 'Flip',
  toolAddLight: 'Add Light',
  toolBackground: 'Background',
  toolForeground: 'Foreground',
  toolPreview: 'Preview',

  toolSaveFile: 'Save & Export',
  toolExit: 'Quit',

  toolUndo: 'Undo',
  toolRedo: 'Redo',

  warningMax8Lights: 'You can add at most 8 lights.',
  warningAreYouSure: 'Are you sure?',
  warningCameraPermissions: 'Allow Camera access in Settings 👉 Reflight Studio 👉 Camera',

  differentialPreview: 'Before & After',
  closePreview: 'Close Preview',

  layerBokeh: 'Bokeh',
  layerSharpness: 'Sharpness',
  layerBrightness: 'Brightness',
  layerColor: 'Color',
  layerContrast: 'Contrast',
  layerDistance: 'Distance',
  layerFalloff: 'Falloff',
  layerVignette: 'Vignette',

  lightStrength: 'Strength',
  lightSpread: 'Spread',
  lightColor: 'Color',
  lightSaturation: 'Saturation',
  lightBrightness: 'Brightness',
  lightOffset: 'Offset',

  sliderNear: 'near',
  sliderFar: 'far',
  sliderGentle: 'gentle',
  sliderQuick: 'sharp',
  sliderOn: 'on',
  sliderOff: 'off',
  sliderNarrow: 'narrow',
  sliderWide: 'wide',

  exportNativeStarted: 'Exporting...',
  exportNativeSuccess: 'Picture saved',
  exportNativeErrorExport: `Can't save the Picture`,
  exportNativeErrorAlbum: `Can't access Photos`,

  download1: 'Hold finger on picture',
  download2: 'and press',
  download3: 'Save to Photos',

  promoTop: 'One app to refine and add light to your pictures & art.',
  promoCTA: 'Choose picture to try!',

  artbookExample: 'example',
  artbookTitle: 'Reflight Studio',

  commandAdjustLayer: "Adjust Layer",
  commandAdjustLight: "Adjust Light",
  commandCrop: "Crop",
  commandFlip: "Flip",
  commandRotate: "Rotate",

  ratingTitle: 'Enjoying Reflight Studio?',
  ratingMessage: 'We value your feedback! Do you have a moment to rate our app?',
  ratingNotNow: 'Not now',
};

const translationThai = {
  openPicture: 'เลือกภาพถ่าย',
  preparingPicture: 'แป๊บหนึ่งนะค่ะ',
  cancelOption: 'ละทิ้ง?',
  cancellingPicture: 'กำลังละทิ้งค่ะ',

  toolRevert: 'รีเซ็ต',
  toolRotate: 'หมุน',
  toolCrop: 'ครอป',
  toolFlip: 'พลิก',
  toolAddLight: 'หลอดไฟ',
  toolBackground: 'พื้นหลัง',
  toolForeground: 'พื้นหน้า',
  toolPreview: 'ดูผลลัพธ์',

  toolSaveFile: 'บันทึกไฟล์',
  toolExit: 'ปิดไฟล์',

  toolUndo: 'undo',
  toolRedo: 'redo',

  warningMax8Lights: 'สูงสุด8หลอดไฟ หนุ่มหล่อ',
  warningAreYouSure: 'คุณแน่ใจมั้ยนะ?',
  warningCameraPermissions: 'เปิดใช้งานกล้องใน Settings 👉 Reflight Studio 👉 Camera',

  differentialPreview: 'ก่อนหลัง',
  closePreview: 'ปิด',

  layerBokeh: 'เลนส์เบลอ',
  layerSharpness: 'ความคมชัด',
  layerBrightness: 'ความสว่าง',
  layerColor: 'ความอิ่มตัว',
  layerContrast: 'ความคมชัด',
  layerDistance: 'พิสัย',
  layerFalloff: 'การหายไป',
  layerVignette: 'ขอบความมืด',

  lightStrength: 'ความเข้ม',
  lightSpread: 'ขอบเขต',
  lightColor: 'สี',
  lightSaturation: 'ความอิ่มตัว',
  lightBrightness: 'ความสว่าง',
  lightOffset: 'พิสัย',

  sliderNear: 'ใกล้',
  sliderFar: 'ไกล',
  sliderGentle: 'ละมุน',
  sliderQuick: 'สาก',
  sliderOn: 'เปิด',
  sliderOff: 'ปิด',
  sliderNarrow: 'แคบ',
  sliderWide: 'กว้าง',

  exportNativeStarted: 'กำลังบันทึก...',
  exportNativeSuccess: 'บันทึกเรียบร้อย',
  exportNativeErrorExport: 'บันทึกไม่ได้',
  exportNativeErrorAlbum: 'เข้าถึงอัลบั้มไม่ได้',

  download1: 'กดนิ้วบนรูปภาพ',
  download2: 'จากนั้นกด',
  download3: 'บันทึกลงในรูปภาพ',

  promoTop: 'แอพเดียวที่ช่วยปรับแต่งภาพถ่ายและศิลปะของคุณ',
  promoCTA: 'เลือกภาพเพื่อลอง!',

  artbookExample: 'ตัวอย่าง',
  artbookTitle: 'อาร์ตบุ๊ค',

  commandAdjustLayer: "ปรับเลเยอร์",
  commandAdjustLight: "ปรับแสง",
  commandCrop: "ครอป",
  commandFlip: "พลิก",
  commandRotate: "หมุน",

  ratingTitle: 'ชอบReflight Studioหรือ?',
  ratingMessage: 'เราให้ความสำคัญกับความคิดเห็นของคุณ! คุณมีเวลาให้คะแนนแอพของเราไหม?',
  ratingNotNow: 'ไม่ตอนนี้',
};

const translationJapanese = {
  openPicture: '写真を開く',
  preparingPicture: '準備中',
  cancelOption: 'キャンセル?',
  cancellingPicture: 'キャンセル中',

  toolRevert: 'リセット',
  toolRotate: '回転',
  toolCrop: '切り取り',
  toolFlip: '反転',
  toolAddLight: 'ライト',
  toolBackground: '背景',
  toolForeground: '前景',
  toolPreview: 'プレビュー',

  toolSaveFile: '保存',
  toolExit: '終了',

  toolUndo: '元に戻す',
  toolRedo: 'やり直し',

  warningMax8Lights: 'ライトは最大8個までです。',
  warningAreYouSure: '本当によろしいですか？',
  warningCameraPermissions: '設定 👉 Reflight Studio 👉 カメラでカメラアクセスを許可してください',

  differentialPreview: '比較',
  closePreview: '閉じる',

  layerBokeh: 'ボケ',
  layerSharpness: 'シャープネス',
  layerBrightness: '明るさ',
  layerColor: '色',
  layerContrast: 'コントラスト',
  layerDistance: '距離',
  layerFalloff: '減衰',
  layerVignette: 'ビネット',

  lightStrength: '強さ',
  lightSpread: '広がり',
  lightColor: '色',
  lightSaturation: '彩度',
  lightBrightness: '明るさ',
  lightOffset: 'オフセット',

  sliderNear: '近い',
  sliderFar: '遠い',
  sliderGentle: '優しい',
  sliderQuick: '鋭い',
  sliderOn: 'オン',
  sliderOff: 'オフ',
  sliderNarrow: '狭い',
  sliderWide: '広い',

  exportNativeStarted: '保存中...',
  exportNativeSuccess: '保存しました',
  exportNativeErrorExport: '保存できませんでした',
  exportNativeErrorAlbum: 'アルバムにアクセスできませんでした',

  download1: '写真をタップして',
  download2: '保存を選択',

  promoTop: '写真やアートに光を追加して編集するためのアプリ',
  promoCTA: '写真を選んで試してみてください！',

  artbookExample: '例',
  artbookTitle: 'アートブック',

  commandAdjustLayer: "レイヤー調整",
  commandAdjustLight: "ライト調整",
  commandCrop: "切り取り",
  commandFlip: "反転",
  commandRotate: "回転",

  ratingTitle: 'Reflight Studioを楽しんでいますか？',
  ratingMessage: 'フィードバックを大切にしています！アプリを評価していただけますか？',
  ratingNotNow: '今はしない',
};

const userLanguagePreference = navigator.language ||
  // @ts-ignore
  navigator.userLanguage ||
  navigator.languages[0];
  
export const userLanguageIsThai = userLanguagePreference.includes('th');
export const translation = [
  userLanguagePreference.includes('th') && translationThai,
  userLanguagePreference.includes('jp') && translationJapanese,
  userLanguagePreference.includes('ja') && translationJapanese,
].filter(Boolean)[0] || translationEnglish;
