import { Capacitor } from "@capacitor/core";
import { analyticsReturningSession, analyticsUuid, hasRatedApp } from "./analytics";
import { getEnvName } from "./debug";

const env = getEnvName();

export const messageDiscord = (message: string) => {
  const platform = Capacitor.getPlatform();

  if (env === 'dev') {
    return;
  }

  fetch(
    `https://discord.com/api/webhooks/1315869339089637498/-MsE2J4P_0jUeBNyro3eP-1joVZaUZXdNCF3VIzxZYEji3jN_sL7acqVo6Rdf7m9IxE6`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        content: [
          `[${analyticsReturningSession ? '🟠 ret' : '💚 new'}]`,
          env === 'prod' ? '[prod]' : '[dev] ',
          platform === 'web' ? `[.${window.location.host.split('.').slice(-1)[0]}]` : null,
          `[${platform}]`,
          platform === 'web' ? null : `[⭐️${hasRatedApp ?? '-'}]`,
          `[${analyticsUuid?.split('-')[0]}]`,
          message
        ].filter(Boolean).join(' ')
      })
    }
  );
};
