import { Capacitor } from "@capacitor/core";

// NOTE Example: logImage(layer, state.file.height / state.file.width);
export const logImage = (base64: string, aspectRatio: number = 1.0) => {
  console.log("%c Image", `background-image: url('${base64}');
    background-size: 256px ${aspectRatio * 256}px;
    background-repeat: no-repeat;
    line-height: 1px;
    font-size: 1px;
    padding: 256px;
    image-rendering: crisp-edges;`);
};

export const getEnvName = () => {
  const platform = Capacitor.getPlatform();

  if (platform === 'web') {
    return window.location.href.includes('localhost') ? 'dev' : 'prod';
  }

  return 'prod';
};
