import { create } from "zustand";
import { Box, Coords, FileContract, HistoryStep, Plane, Tool } from "../types/editorState";
import { Command } from "../types/commands";
import { Layer, Light } from "../types/relight";
import { Config } from "../config";
import { analyticsUuid, recordExport } from "../utils/analytics";
import { Highlight } from "../utils/texture-highlights";

let pendingSave = false;

export type EditorState = {
  editorVersion: number;
  file: FileContract | null;
  tool: Tool;
  workingArea: Plane | null;
  workingAreaRotation: number;
  workingAreaFlip: boolean;
  workingAreaDifferentialPreviewRatio: number;
  cropBox: Box | null;
  viewportCenter: Coords;
  historyBack: HistoryStep[];
  historyForth: HistoryStep[];
  notification: string | null;
  leftBar: 'modifiers' | 'layers';
  lights: Light[];
  background: Layer;
  foreground: Layer;
  exportQueued: boolean;
  downloadPrompt: string | null;
  highlights: Highlight[];
  expectedWait: number | null;

  reset: () => void;

  execute: (command: Command<any>, payload: any) => void;
  setFile: (file: FileContract | null) => void;
  setTool: (tool: Tool) => void;
  setWorkingArea: (workingArea: Plane | null) => void;
  setWorkingAreaRotation: (rotation: number) => void;
  setWorkingAreaFlip: (flip: boolean) => void;
  setWorkingAreaDifferentialPreviewRatio: (ratio: number) => void;
  setCropBox: (box: Box | null) => void;
  setViewportCenter: (center: Coords) => void;
  showNotification: (message: string | null) => void;
  setLeftBar: (leftBar: 'modifiers' | 'layers') => void;
  setLights: (lights: Light[]) => void;
  setBackground: (background: Layer) => void;
  setForeground: (foreground: Layer) => void;
  setHighlights: (highlights: Highlight[]) => void;
  setExpectedWait: (expectedWait: number | null) => void;

  undo: () => void;
  redo: () => void;
  setHistory(historyBack?: HistoryStep[], historyForth?: HistoryStep[]): void;

  getRecentImages: () => Promise<string[]>;
  export: () => void;
  showDownloadPrompt: (base64: string | null) => void;
  saveToArtbook: () => Promise<void>;
};

export const useEditorState = create<EditorState>((set, get) => ({
  editorVersion: Config.editorVersion,
  file: null,
  tool: "move",
  workingArea: null,
  workingAreaRotation: 0.0,
  workingAreaFlip: false,
  workingAreaDifferentialPreviewRatio: 1.0,
  cropBox: null,
  viewportCenter: { x: 0.0, y: 0.0 },
  historyBack: [],
  historyForth: [],
  notification: null,
  leftBar: 'modifiers',
  lights: [],
  background: {
    sharpness: 1.0,
    brightness: 0.4,
    saturation: 0.5,
    contrast: 0.6,
    bokeh: 0.0,
    vignette: 0.0,
    offset: 0.5,
    offsetStride: 0.5,
  },
  foreground: {
    sharpness: 1.0,
    brightness: 0.6,
    saturation: 0.5,
    contrast: 0.6,
    bokeh: 0.0,
    vignette: 0.0,
  },
  exportQueued: false,
  downloadPrompt: null,
  highlights: [],
  expectedWait: null,

  reset: () => {
    const editorState = get();

    if (editorState.file) {
      editorState.setWorkingArea({
        width: editorState.file.width / editorState.file.height,
        height: 1.0
      });
    }

    editorState.showDownloadPrompt(null);
    editorState.setTool("move");
    editorState.setWorkingAreaRotation(0.0);
    editorState.setWorkingAreaFlip(false);
    editorState.setWorkingAreaDifferentialPreviewRatio(1.0);
    editorState.setCropBox({ x1: 0.0, y1: 0.0, x2: 1.0, y2: 1.0 });
    editorState.setViewportCenter({ x: 0.0, y: 0.0 });
    editorState.setHistory([], []);
    editorState.showNotification(null);
    editorState.setLeftBar('modifiers');
    editorState.setLights([]);
    editorState.setBackground({
      sharpness: 1.0,
      brightness: 0.45,
      saturation: 0.45,
      contrast: 0.45,
      bokeh: 1.0,
      vignette: 0.0,
      offset: 0.2,
      offsetStride: 0.2,
    });
    editorState.setForeground({
      sharpness: 1.0,
      brightness: 0.55,
      saturation: 0.55,
      contrast: 0.55,
      bokeh: 1.0,
      vignette: 0.1,
    });
  },
  execute: (command, payload) => {
    const state = get();

    state.historyForth.length = 0;

    command.do(payload);
  },
  setFile: (file) => {
    const { file: currentFile } = get();

    if (currentFile) {
      if (currentFile.base64 && file?.base64 !== currentFile.base64) {
        URL.revokeObjectURL(currentFile.base64);
      }

      if (currentFile.depthBase64 && file?.depthBase64 !== currentFile.depthBase64) {
        URL.revokeObjectURL(currentFile.depthBase64);
      }

      if (currentFile.sourceBase64 && file?.sourceBase64 !== currentFile.sourceBase64) {
        URL.revokeObjectURL(currentFile.sourceBase64);
      }
    }

    set({ file });
  },
  setTool: (tool) => set({ tool }),
  setWorkingArea: (workingArea) => set({ workingArea }),
  setWorkingAreaRotation: (workingAreaRotation) => set({ workingAreaRotation }),
  setWorkingAreaFlip: (workingAreaFlip) => set({ workingAreaFlip }),
  setWorkingAreaDifferentialPreviewRatio: (workingAreaDifferentialPreviewRatio) => set({ workingAreaDifferentialPreviewRatio }),
  setCropBox: (cropBox) => set({ cropBox }),
  setViewportCenter: (viewportCenter) => set({ viewportCenter }),
  showNotification: (notification) => set({ notification }),
  setLeftBar: (leftBar) => set({ leftBar }),
  setLights: (lights) => set({ lights }),
  setBackground: (background) => set({ background }),
  setForeground: (foreground) => set({ foreground }),
  setHighlights: (highlights) => set({ highlights }),
  setExpectedWait: (expectedWait) => set({ expectedWait }),

  undo: () => {
    const state = get();
    const historyStep = state.historyBack.pop();

    if (!historyStep) {
      return;
    }

    const [command, payload, viewportCenter] = historyStep;

    command.undo(payload);

    if (viewportCenter) {
      state.setViewportCenter(viewportCenter);
    }

    state.showNotification(`Undo ${command.name}`);
  },
  redo: () => {
    const state = get();
    const historyStep = state.historyForth.pop();

    if (!historyStep) {
      return;
    }

    const [command, payload, viewportCenter] = historyStep;

    command.do(payload);

    if (viewportCenter) {
      state.setViewportCenter(viewportCenter);
    }

    state.showNotification(`Redo ${command.name}`);
  },
  setHistory: (historyBack, historyForth) => {
    const update: Partial<EditorState> = {};

    if (historyBack) {
      update.historyBack = historyBack.slice(-32);
    }

    if (historyForth) {
      update.historyForth = historyForth.slice(-32);
    }

    set(update);
  },

  getRecentImages: async (): Promise<string[]> => {
    const imagesList: string[] = [];

    // TODO This can be removed entirely

    return imagesList;
  },
  export: () => {
    recordExport();

    set({ exportQueued: true });

    get().saveToArtbook();
  },
  showDownloadPrompt: (downloadPrompt) => set({ downloadPrompt }),
  saveToArtbook: async () => {
    const {
      file,
      workingAreaRotation,
      cropBox,
      lights,
      background,
      foreground,
    } = get();
    const ownerUuid = analyticsUuid;
    const isArtbookView = window.location.host.startsWith("artbook.");

    if (!isArtbookView) {
      return;
    }

    if (!file || pendingSave || !ownerUuid) {
      return;
    }

    pendingSave = true;

    try {
      await fetch(`${Config.apiUrl}/artbook-state?id=${file.path}&ownerUuid=${ownerUuid}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workingAreaRotation,
          cropBox,
          lights,
          background,
          foreground,
        }),
      });
    } catch {
      // NOTE Noop
    }

    pendingSave = false;
  },
}));
